import LanguageService from "../../service/LanguageService";
let model = {};

// dữ liệu form
model.dataForm = {
  id: undefined, // optional - Mã tự sinh
  label: undefined, // required - Tên menu
  label_lang: {
    vi: "",
    en: ""
  },
  index: undefined, // optional - Thứ tự sắp xếp
  parent_id: undefined, // optional, ref: menu - Mã menu cha
  icon_id: undefined, // optional, ref: mushroom_file - Mã file icon
  roles: [], // required, array items: required, enum(Admin, AdminLocal, Edit, User) - Danh sách quyền được hiển thị
  visible: true, // required - Cho biết có hiển thị hay không
  domains: [], // required, array items: required, enum(primary, probe, bras) - Phân hệ hiển thị
  not_in_domains: [],
  target_domain: undefined, // required, enum(primary, probe, bras) - Phân hệ đích
  target_type: undefined, // required, enum(static, service, table_screen, usecase) - Phân loại trang đích
  target_id: undefined, // optional, ref: service|table_screen|usecase - Không bắt buộc với target_type=static, còn lại là bắt buộc
  link: undefined, // required - Đường dẫn menu
  created_time: undefined, // required - Thời điểm tạo
  creator_id: undefined, // required, ref: user - Mã người tạo
};
model.targetDomains = [
  { value: "primary", label: "Primary" },
  { value: "probe", label: "Probe" },
  { value: "bras", label: "Bras" },
];
model.targetTypeByPrimary = [
  { value: "static", label: "Static" },
  { value: "dynamic", label: "Dynamic" },
];
model.targetTypeByProbe = [
  { value: "static", label: "Static" },
  { value: "service", label: "Service" },
  { value: "table_screen", label: "Table screen" },
  { value: "usecase", label: "Usecase" },
  { value: "dynamic", label: "Dynamic" },
];
model.targetType = [
  { value: "static", label: "Static" },
  { value: "service", label: "Service" },
  { value: "table_screen", label: "Table screen" },
  { value: "usecase", label: "Usecase" },
  { value: "dynamic", label: "Dynamic" },
];
model.visibleMenu = [
  {
    value: "true",
    get label() {
      return LanguageService?.lang?.['t-display'] ?? 't-display'
    }
  },
  {
    value: "false",
    get label() {
      return LanguageService?.lang?.['t-hide'] ?? 't-hide'
    }
  },
];
model.roles = [
  { value: "Admin", label: "Super Admin" },
  { value: "AdminPrimary", label: "Admin" },
  { value: "AdminLocal", label: "AdminLocal" },
  { value: "Edit", label: "Edit" },
  { value: "User", label: "View" },
];
model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: "index",
  defaultSort: ["index", "descending"],
  fields:
    "id, label, index, parent_id, icon_id, roles, visible, domains,not_in_domains, target_domain, target_type, target_id, link, created_time, creator_id",
  filters: "parent_id:is_null:true",
  dataSearch: {
    value: {
      label: "",
      visible: "",
      target_domain: "",
      target_type: "",
      parent_id: "",
    },
    operator: {
      label: ":regex_i:",
      visible: "",
      target_domain: "",
      target_type: "",
      parent_id: "",
    },
  },
};

export default model;
