<script setup>
import Layout from "../../layouts/main.vue";
import { onMounted, onBeforeUnmount, reactive, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import MethodService from "../../service/MethodService";
import DataForm from "./dataFormMenu";
import mushroom from "cem-primary-api";
import { Filter } from "mushroomjs";
import Swal from "sweetalert2";
import toastr from "toastr";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { langWeb } from "../../stores/lang";
import LanguageService from "../../service/LanguageService";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
let loading = ref(true);
const router = useRouter();
const route = useRoute();
const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const visibleMenuList = ref(MethodService.copyObject(DataForm.visibleMenu));
const targetDomainsSelect = MethodService.copyObject(DataForm.targetDomains);
const targetTypeSelect = MethodService.copyObject(DataForm.targetType);
const dataTables = reactive({ value: [] }); // data bảng
const pageSizeDefault = ref(20);
const rolesAccount = ref("");
const listMenuCha = ref([]);
const getListMenuCha = async () => {
  listMenuCha.value = [];
  try {
    const response = await mushroom.menu.getAllAsync({
      filters: Filter.isNull("parent_id").build(),
      sort: "index",
      fields: "id, label",
    });
    if (response.result?.length > 0) {
      response.result.forEach((item) => {
        listMenuCha.value.push({
          value: item.id,
          label: item.label,
        });
      });
    }
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getListMenu = async () => {
  if (tableRules.dataSearch.value["parent_id"] === "true")
    tableRules.dataSearch.value["parent_id"] = "";
  let dataFilter = {
    filters: tableRules.filters,
    fields: tableRules.fields,
    limit: tableRules.limit,
    offset: tableRules.offset,
    sort: tableRules.sort,
  };
  router
    .push({
      name: "ListMenu",
      query: {
        limit: tableRules.limit,
        page: tableRules.page,
        sort: tableRules.sort,
        filters: tableRules.filters,
        showFormSearch: tableRules.showFormSearch,
      },
    })
    .catch(() => {});
  try {
    const response = await mushroom.menu.listAsync(dataFilter);
    let res = await changeData(response.result);
    dataTables.value = res;
    tableRules.total = response.meta.total;
    tableRules.page = response.meta.offset / response.meta.limit + 1;
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loading.value = false;
  }
};
const changeData = async (data) => {
  data.forEach((element) => {
    element.domainsString = element.domains.join(", ");
    element.noDomainsString =
      element?.not_in_domains?.length > 0
        ? element.not_in_domains.join(", ")
        : [];
    if (element.icon_id) {
      element.iconSrc = MethodService.thumbIcon(element.icon_id, 32);
    } else element.iconSrc = null;
  });
  return data;
};
const renderHtmlAlert = async (idsCardItem, nameMenu) => {
  let htmlDiv =
    idsCardItem?.length > 0
      ? `<p style="font-weight: bold; margin-left: 20px; text-align: left">Menu <span style=" color: #F56C6C">${MethodService.encodeHtml(
          nameMenu
        )}</span> ${t("t_menu_at")} </p>`
      : t("t_being_assigned_menu");
  if (idsCardItem?.length > 0) {
    const resCardItems = await mushroom.card_item.getAllAsync({
      fields: "id,title,card_id",
      filters: Filter.in("id", idsCardItem).build(),
    });

    const idsCard = resCardItems.result.map((item) => item.card_id);
    const resCard = await mushroom.card.getAllAsync({
      fields:
        "id,dashboard,service_id,uc_id,table_screen_id,dynamic_dashboard_id,title",
      filters: Filter.in("id", idsCard).build(),
    });
    for (let item of resCard.result) {
      item.dashboard_name = "";
      switch (item.dashboard) {
        case "main":
          item.dashboard_name = "Dashboard probe";
          break;
        case "customer":
          item.dashboard_name = "Dashboard khách hàng";
          break;
        case "session":
          item.dashboard_name = "Dashboard session";
          break;
        case "service": {
          let service = await mushroom.service.findByIdAsync({
            id: item.service_id,
          });
          item.dashboard_name = "Service " + (service.result.name ?? "");
          break;
        }
        case "uc": {
          let usecase = await mushroom.usecase.findByIdAsync({
            id: item.uc_id,
          });
          item.dashboard_name = "UC " + (usecase.result.name ?? "");
          break;
        }
        case "tablescreen": {
          let table_screen = await mushroom.table_screen.findByIdAsync({
            id: item.table_screen_id,
          });
          item.dashboard_name =
            "Tablescreen " + (table_screen.result.name ?? "");
          break;
        }
        case "dynamic": {
          let dynamic = await mushroom.dynamic_dashboard.findByIdAsync({
            id: item.dynamic_dashboard_id,
          });
          let menu = !dynamic.result
            ? null
            : await mushroom.menu.findByIdAsync({
                id: dynamic.result.menu_id,
              });
          let parent_menu = !menu
            ? null
            : await mushroom.menu.findByIdAsync({
                id: menu.result.parent_id,
              });
          item.dashboard_name =
            !menu && !parent_menu
              ? ""
              : `Menu ${
                  !parent_menu.result ? "" : parent_menu.result.label + " / "
                } ${!menu.result ? "" : menu.result.label}`;
          break;
        }
        default:
          item.dashboard_name = "";
      }
    }

    resCardItems.result.forEach((card_item) => {
      resCard.result.forEach((card) => {
        if (card_item.card_id === card.id) {
          const itemDiv = `<p style="font-size: 14px; text-align: left; margin-left: 30px"><span style="color: #F56C6C">*</span> <span style="color: #409EFF">${MethodService.encodeHtml(
            card.dashboard_name
          )}</span> tại card-item <span style="color: #67C23A">${MethodService.encodeHtml(
            card_item.title
          )}</span> của card <span style="color: #E6A23C">${MethodService.encodeHtml(
            card.title
          )}</span> </p>`;
          htmlDiv += itemDiv;
        }
      });
    });
  }

  Swal.fire({
    icon: "error",
    allowOutsideClick: false,
    width: 900,
    html: htmlDiv,
  });
};
const fn_handle = async (type, row) => {
  const resCheckHaveChildren = await mushroom.menu.listAsync({
    filters: Filter.eq("parent_id", row.id).build(),
    limit: 1,
  });
  if (type === "show-menu-child") {
    tableRules.dataSearch.value["parent_id"] = row.id;
    tableRules.showFormSearch = true;
    fn_TimKiem();
  }
  if (type === "update") {
    router.push({
      name: "CapNhatMenu",
      params: { id: row.id },
    });
  }
  if (type === "delete") {
    if (resCheckHaveChildren?.meta?.total > 0) {
      toastr.error(t("t_cant_delete_menu"));
    } else {
      Swal.fire({
        title: t("t_warning"),
        text: t("t_data_had_delete"),
        icon: "error",
        confirmButtonColor: "#f46a6a",
        confirmButtonText: t("swal_accept"),
        cancelButtonText: t("swal_cancel"),
        showCancelButton: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            if (row.target_type === "dynamic") {
              const resDynamicDashboard =
                await mushroom.dynamic_dashboard.listAsync({
                  filters: Filter.eq("menu_id", row.id).build(),
                });
              if (resDynamicDashboard.result?.length > 0) {
                await mushroom.dynamic_dashboard.deleteAsync({
                  id: resDynamicDashboard.result[0].id,
                });
              }
            }
            const responseGroup = await mushroom.menu.deleteAsync({
              id: row.id,
            });
            if (responseGroup.result)
              toastr.success(t("toastr_delete_success"));
            await getListMenu();
          } catch (e) {
            if (e?.code === 500) {
              if (e?.subCode === 2000)
                toastr.error(t("t_error_delete_menu_2000"));
              if (e?.subCode === 2001)
                toastr.error(t("t_error_delete_menu_2001"));
              if (e?.subCode === 2002)
                renderHtmlAlert(e.meta.card_item_ids, row.label);

              if (e?.subCode === 2003)
                toastr.error(t("t_error_delete_menu_2003"));
              if (e?.subCode === 2004)
                toastr.error(t("t_error_delete_menu_2004"));
              if (e?.subCode === 2005)
                toastr.error(t("t_error_delete_menu_2005"));
            } else MethodService.showError(e.code);
          }
        }
      });
    }
  }
};
const fn_showFormTimKiem = () => {
  tableRules.showFormSearch = !tableRules.showFormSearch;
};
const fn_TimKiem = async () => {
  tableRules.dataSearch.value["label"] =
    tableRules.dataSearch.value["label"]?.trim() ?? "";
  tableRules.filters = MethodService.filterTable(
    JSON.stringify(tableRules.dataSearch)
  );
  if (tableRules.dataSearch.value["parent_id"] === "") {
    tableRules.filters = tableRules.filters + "&parent_id:is_null:true";
  }

  tableRules.offset = 0;
  tableRules.page = 1;

  await getListMenu();
};
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  pageSizeDefault.value = limit;
  fn_tableChangeOffset(1);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tablePrevClick = (page) => {
  fn_tableChangeOffset(page - 1);
};
const fn_tableNextClick = (page) => {
  fn_tableChangeOffset(page + 1);
};
const fn_tableChangeOffset = async (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * pageSizeDefault.value;
  await getListMenu();
};
const fn_tableSortChange = async (column) => {
  column.order == "ascending"
    ? (tableRules.sort = column.prop)
    : column.order == "descending"
    ? (tableRules.sort = "-" + column.prop)
    : (tableRules.sort = "");
  loading.value = true;
  await getListMenu();
};
const fn_hashUrl = (routeQuery, table_rules) => {
  routeQuery = route.query; // không phải router
  table_rules = tableRules;
  MethodService.hashUrl(routeQuery, table_rules);
  pageSizeDefault.value = tableRules.limit;
};
const fn_ThemMoiMenu = () => {
  router.push({ name: "ThemMoiMenu" });
};
const fn_getListMenu = async () => {
  fn_hashUrl();
  await getListMenu();
};

watch(
  () => lang_web.showLang,
  () => {
    lang.value = lang_web.showLang.toLocaleUpperCase();
    LanguageService.lang = LanguageService[lang.value];
    visibleMenuList.value = MethodService.copyObject(DataForm.visibleMenu);
  }
);
watch(
  () => route.query,
  async (routeQuery) => {
    if (Object.entries(routeQuery).length === 0) {
      tableRules.filters = "parent_id:is_null:true";
      await fn_getListMenu();
    }
  }
);
onMounted(async () => {
  const response = JSON.parse(localStorage.getItem("userInfo"));
  rolesAccount.value = response.result.roles[0];
  await getListMenuCha();
  await fn_getListMenu();
  window.addEventListener("popstate", fn_getListMenu);
});
onBeforeUnmount(() => {
  window.removeEventListener("popstate", fn_getListMenu);
});
</script>

<template>
  <Layout>
    <div class="card">
      <div class="card-header">
        <h3 class="text-uppercase mb-0">{{ $t("t-list-menu") }}</h3>
        <div class="text-end">
          <div class="btn-header">
            <button
              class="btn btn-soft-secondary btn-border me-2"
              @click="fn_showFormTimKiem()"
            >
              <i class="ri-search-line"></i>
              {{ $t("t-search") }}
            </button>
            <button
              class="btn btn-secondary btn-border"
              @click="fn_ThemMoiMenu"
              v-if="rolesAccount === 'Admin'"
            >
              <i class="ri-add-circle-line"></i>
              {{ $t("t-add") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="padding: 0 0 15px 0">
        <div class="main-body">
          <div class="main-body-search mx-3 mb-3">
            <b-collapse
              v-model="tableRules.showFormSearch"
              id="myFormSearch"
              class="b-shadow"
            >
              <b-card>
                <el-form
                  label-position="top"
                  ref="ruleFormRef"
                  @submit.prevent
                  :rules="rulesForm"
                  :model="tableRules.dataSearch.value"
                >
                  <b-row>
                    <b-col md="3">
                      <el-form-item :label="$t('t-name-menu')">
                        <el-input
                          clearable
                          v-model="tableRules.dataSearch.value['label']"
                          :placeholder="$t('t-placeholder-name-menu')"
                        ></el-input>
                      </el-form-item>
                    </b-col>
                    <b-col md="3">
                      <el-form-item :label="$t('t-parent-menu')">
                        <el-select
                          filterable
                          v-model="tableRules.dataSearch.value['parent_id']"
                          :placeholder="$t('t-placeholder-parent-menu')"
                          clearable
                        >
                          <el-option
                            v-for="item in listMenuCha"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="3">
                      <el-form-item :label="$t('t-target-domains')">
                        <el-select
                          filterable
                          v-model="tableRules.dataSearch.value['target_domain']"
                          :placeholder="$t('t-placeholder-target-domains')"
                          clearable
                        >
                          <el-option
                            v-for="item in targetDomainsSelect"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="3">
                      <el-form-item :label="$t('t-target-type')">
                        <el-select
                          filterable
                          v-model="tableRules.dataSearch.value['target_type']"
                          :placeholder="$t('t-placeholder-target-type')"
                          clearable
                        >
                          <el-option
                            v-for="item in targetTypeSelect"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                    <b-col md="3">
                      <el-form-item :label="$t('t-menu-show')">
                        <el-select
                          v-model="tableRules.dataSearch.value['visible']"
                          :placeholder="$t('t-place-status')"
                          clearable
                        >
                          <el-option
                            v-for="item in visibleMenuList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </b-col>
                  </b-row>
                  <div class="text-center">
                    <a
                      class="btn btn-soft-secondary btn-border mx-3"
                      style="width: 80px"
                      @click="fn_showFormTimKiem()"
                    >
                      {{ $t("t-hide") }}
                    </a>
                    <button
                      class="btn btn-secondary bg-gradient btn-border waves-effect waves-light"
                      style="width: 100px"
                      native-type="submit"
                      @click="fn_TimKiem()"
                    >
                      {{ $t("t-search") }}
                    </button>
                  </div>
                </el-form>
              </b-card>
            </b-collapse>
          </div>
          <div class="mb-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
          <el-table
            size="small"
            :data="dataTables.value"
            style="width: 100%"
            max-height="550"
            v-loading="loading"
            :empty-text="$t('t-no-data')"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
          >
            <el-table-column
              prop=""
              :label="$t('t-stt')"
              width="70"
              align="center"
            >
              <template #default="scope">
                <div class="text-center">
                  {{ tableRules.offset + scope.$index + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('t-menu-show')"
              width="80"
              align="center"
            >
              <template #default="scope">
                <i
                  v-if="scope.row.visible"
                  style="color: #67c23a"
                  class="ri-checkbox-circle-fill"
                ></i>
                <i
                  v-else
                  style="color: #f56c6c"
                  class="ri-close-circle-fill"
                ></i>
              </template>
            </el-table-column>
            <el-table-column label="Icon" width="80" align="center">
              <template #default="scope">
                <img
                  v-if="scope.row.iconSrc"
                  :src="scope.row.iconSrc"
                  alt="icon menu"
                  height="18"
                  class="icon-background"
                />
                <img
                  v-else
                  src="@/assets/icon/none-icon-menu.svg"
                  alt="none icon menu"
                  height="18"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="label"
              :label="$t('t-name-menu')"
              min-width="200"
              align="start"
              :sortable="true"
            />
            <el-table-column
              prop="domainsString"
              :label="$t('t-domains')"
              min-width="170"
              align="start"
            />
            <el-table-column
              prop="noDomainsString"
              :label="$t('t-no-domains')"
              min-width="170"
              align="start"
            />
            <el-table-column
              prop="target_domain"
              :label="$t('t-target-domains')"
              min-width="120"
              align="start"
            />
            <el-table-column
              prop="target_type"
              :label="$t('t-target-type')"
              min-width="170"
              align="start"
            />
            <el-table-column
              :label="$t('t-action')"
              align="center"
              width="150px"
            >
              <template #default="scope">
                <div class="btn-group-thao-tac">
                  <el-tooltip
                    v-if="!scope.row.parent_id"
                    class="box-item"
                    effect="light"
                    :content="$t('t-list-menu-child')"
                    placement="top"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-info waves-effect waves-light btn-sm"
                      @click="fn_handle('show-menu-child', scope.row)"
                    >
                      <i class="ri-menu-5-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_option')"
                    placement="top"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-success waves-effect waves-light btn-sm"
                      @click="fn_handle('update', scope.row)"
                    >
                      <i class="ri-edit-box-line"></i>
                    </button>
                  </el-tooltip>
                  <el-tooltip
                    class="box-item"
                    effect="light"
                    :content="$t('action_delete')"
                    placement="top"
                  >
                    <button
                      type="button"
                      class="btn btn-soft-danger waves-effect waves-light btn-sm"
                      @click="fn_handle('delete', scope.row)"
                    >
                      <i class="ri-delete-bin-6-line"></i>
                    </button>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="mt-3" style="float: right">
            <el-pagination
              small
              v-model:currentPage="tableRules.page"
              v-model:pageSize="pageSizeDefault"
              :page-sizes="tableRules.lengthMenu"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableRules.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
              @prev-click="fn_tablePrevClick"
              @next-click="fn_tableNextClick"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style scoped lang="scss">
.icon-background {
  background: #151529;
}
</style>
